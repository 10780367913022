// Please use alphabetical order for end endpoints and hooks being exported

import {
    accountDepartmentAssignmentTypes,
    accountRoleTypes,
    clients,
    clientSettings,
    clientStatuses,
    clientBusinessChannels,
    clientIntegrationTypes,
    clientOrderIssues,
    clientStatusChangedNotificationConfig,
    contractorBlocklistReasons,
    contractorServiceTypes,
    contractorStatuses,
    contractorJobTypeEligibilityTypes,
    countries,
    directedInvoiceTypes,
    documentTradeTypes,
    financialInvoiceLineOfBusinesses,
    financialSyncQueueModels,
    financialSyncQueueStatuses,
    financialBillingStatuses,
    financialPaymentStatuses,
    financialStatuses,
    financialSyncQueueResolveReasons,
    financialTerms,
    opsAccounts,
    orderCancelReasons,
    orderContactTypes,
    orderFulfillmentTypes,
    orderHoldReasons,
    orderNotificationTypes,
    orderStatuses,
    projectContactTypes,
    projectHoldReasonTypes,
    propertyStatuses,
    propertyTypes,
    quoteIssues,
    quoteReasons,
    quoteStatuses,
    quoteReopenReasons,
    quoteRequestRevisionTypes,
    quoteRejectTypes,
    quoteTypes,
    states,
    timezones,
    workOrderContactTypes,
    workOrderIssueRejectedTypes,
    workOrderIssues,
    workOrderPhotoTypes,
    workOrderPriorityTypes,
    workOrderRequirementApprovalReasons,
    workOrderStatuses,
    workOrderTypes,
    productAttributeTypes,
    folderAttachmentTypes,
    adjustmentTypes,
    lineItemAdjustmentReasonTypes,
    contractorClients,
    orderLogisticsTypes,
    orderFulfillmentMethods,
    contractorFileTypes,
    orderIssues,
    contractorTeamMemberFileTypes,
    contractorTeamMemberNotifications,
    contractorTeamMemberTitles,
    accountPreferenceTypes,
    orderTypes,
    accountDepartmentTypes,
    assetPhotoTypes,
    assetConfigurationCategories,
    vendorsApproved,
    projectStatuses,
    projectScopeTypes,
    managedProductCategories,
    productCategories,
    contractorQualificationTypes,
    projectAttachmentTypes,
    propertyAttachmentTypes,
    templateTypes,
    financialSalesOrderLineOfBusinesses,
    documentTypes,
    orderReturnReasons,
    orderReturnStatuses,
    orderReturnCancelReasons,
    assetUntaggableReasons,
    projectTypes,
    workOrderPdfTypes,
    draftStatuses,
    vendorsAll,
    assetStatuses,
    usStates,
    warrantyOwnerTypes,
    warrantyOwnerInstallTypes,
    warrantyRegistrationStatuses,
    workOrderContactPreferenceTypes,
    unitOfMeasures,
    managedVendorDistributionTypes,
    managedVendorIntegrationTypes,
    managedProductAttributeTypes,
    managedProductPricingTypes,
    managedVendorStatuses,
    managedVendorTypes,
    vendorManufacturers,
    assetRetirementReasons,
    accountRoles,
    quotePdfTypes,
    contractorExternalCertifications,
    contractorInternalCertifications,
    contractorCredentials,
    clientFreightTypes,
    currencies,
    projects,
    accountTypes,
    ticketProviders,
    assetRecommendationTypes,
    assetRepairReasons,
    lhsRiskTypes,
    tsmRegions,
    workOrderReassignReasons,
} from 'common/services/MetaService';

import {
    getAccount,
    isClientAccount,
    isMotiliAccount,
} from 'common/services/AuthService';
import { getCompany } from 'client/services/ClientCompanyService';
import emptyApi from '../baseApi';
import {
    AccountDepartmentAssignmentTypeAPI,
    AccountDepartmentTypeAPI,
    AccountRoleAPI,
    AccountPreferenceTypeAPI,
    AccountRoleTypeAPI,
    AccountTypeAPI,
    AssetConfigurationCategoryAPI,
    AssetPhotoTypeAPI,
    AssetRecommendationTypeAPI,
    AssetRepairReasonAPI,
    AssetRetirementReasonAPI,
    AssetStatusAPI,
    AssetUntaggableReasonAPI,
    ClientAPI,
    ClientBusinessChannelAPI,
    ClientFreightTypeAPI,
    ClientIntegrationTypeAPI,
    ClientOrderIssueAPI,
    ClientSettingAPI,
    ClientStatus,
    ClientStatusChangedNotificationConfigAPI,
    ContractorBlocklistReasonAPI,
    ContractorExternalCertificationAPI,
    ContractorInternalCertificationAPI,
    ContractorClientAPI,
    ContractorCredentialAPI,
    ContractorFileTypeAPI,
    ContractorQualificationTypeAPI,
    ContractorServiceTypeAPI,
    ContractorStatus,
    ContractorTeamMemberFileTypeAPI,
    ContractorTeamMemberNotificationAPI,
    ContractorTeamMemberTitleAPI,
    contractorJobTypeEligibilityTypeAPI,
    CountryAPI,
    CurrencyAPI,
    DirectedInvoiceTypesAPI,
    DocumentTradeTypeAPI,
    DocumentTypeAPI,
    DraftStatusAPI,
    FinancialInvoiceLineOfBusinessesAPI,
    FinancialSalesOrderLineOfBusinessesAPI,
    FinancialStatusAPI,
    FinancialSyncQueueModelAPI,
    FinancialSyncQueueResolveReasonsAPI,
    FinancialSyncQueueStatusAPI,
    FinancialTermAPI,
    FolderAttachmentTypeAPI,
    AdjustmentTypeAPI,
    ManagedProductAttributeTypeAPI,
    ManagedProductCategoryAPI,
    ManagedProductPricingTypeAPI,
    ManagedVendorDistributionTypeAPI,
    ManagedVendorIntegrationTypeAPI,
    ManagedVendorStatusAPI,
    ManagedVendorTypeAPI,
    OpsAccountAPI,
    OrderContactTypeAPI,
    orderFulfillmentMethodAPI,
    OrderFulfillmentTypeAPI,
    OrderIssueAPI,
    OrderLogisticsTypeAPI,
    OrderNotificationTypeAPI,
    OrderReturnCancelReasonApi,
    OrderReturnReasonApi,
    OrderReturnStatusApi,
    OrderStatusAPI,
    OrderTypeAPI,
    ProductAttributeTypeAPI,
    ProductCategoryAPI,
    ProjectAPI,
    ProjectAttachmentTypeAPI,
    ProjectContactTypeAPI,
    ProjectScopeTypeAPI,
    ProjectStatusAPI,
    ProjectTypeAPI,
    PropertyAttachmentTypeAPI,
    PropertyStatusAPI,
    PropertyTypeAPI,
    QuoteIssueAPI,
    QuotePDFTypeAPI,
    QuoteReasonsAPI,
    QuoteReopenReasonsAPI,
    QuoteRequestRevisionTypeAPI,
    QuoteRejectTypeAPI,
    QuoteTypeAPI,
    StateAPI,
    TemplateTypeAPI,
    TicketProviderAPI,
    UnitsofMeasureAPI,
    VendorAPI,
    WarrantyOwnerTypeAPI,
    WarrantyOwnerInstallTypeAPI,
    WarrantyRegistrationStatusesAPI,
    WorkOrderContactPreferenceTypeAPI,
    WorkOrderContactTypeAPI,
    WorkOrderIssueAPI,
    WorkOrderIssueRejectedTypeAPI,
    WorkOrderPDFTypeAPI,
    WorkOrderPhotoTypeAPI,
    WorkOrderPriorityTypeAPI,
    WorkOrderRequirementApprovalReasonAPI,
    WorkOrderStatusAPI,
    WorkOrderTypeAPI,
    LineItemAdjustmentReasonTypeAPI,
    ProjectHoldReasonTypeAPI,
    QuoteStatusAPI,
    LHSRiskType,
    TSM_Regions_API,
    WorkOrderReassignmentReasonAPI,
} from './MetaStoreApi.types';

const apiWithTag = emptyApi.enhanceEndpoints({
    addTagTypes: [
        'accountDepartmentAssignmentTypes',
        'accountDepartmentTypes',
        'accountPreferenceTypes',
        'accountRoles',
        'accountRoleTypes',
        'accountTypes',
        'assetConfigurationCategories',
        'assetPhotoTypes',
        'assetRetirementReasons',
        'assetStatuses',
        'assetUntaggableReasons',
        'clientBusinessChannels',
        'clientFreightTypes',
        'clientIntegrationTypes',
        'clientOrderIssues',
        'clients',
        'clientSettings',
        'clientStatusChangedNotificationConfig',
        'clientStatuses',
        'contractorBlocklistReasons',
        'contractorExternalCertifications',
        'contractorInternalCertifications',
        'contractorClients',
        'contractorCredentials',
        'contractorFileTypes',
        'contractorQualificationTypes',
        'contractorServiceTypes',
        'contractorStatuses',
        'contractorTeamMemberFileTypes',
        'contractorTeamMemberNotifications',
        'contractorTeamMemberTitles',
        'contractorJobTypeEligibilityTypes',
        'countries',
        'currencies',
        'directedInvoiceTypes',
        'documentTradeTypes',
        'documentTypes',
        'draftStatuses',
        'financialBillingStatuses',
        'financialInvoiceLineOfBusinesses',
        'financialPaymentStatuses',
        'financialSalesOrderLineOfBusinesses',
        'financialStatuses',
        'financialSyncQueueModels',
        'financialSyncQueueResolveReasons',
        'financialSyncQueueStatuses',
        'financialTerms',
        'folderAttachmentTypes',
        'adjustmentTypes',
        'lineItemAdjustmentReasonTypes',
        'managedProductAttributeTypes',
        'managedProductCategories',
        'managedProductPricingTypes',
        'managedVendorDistributionTypes',
        'managedVendorIntegrationTypes',
        'managedVendorStatuses',
        'managedVendorTypes',
        'opsAccounts',
        'orderCancelReasons',
        'orderContactTypes',
        'orderFulfillmentMethods',
        'orderFulfillmentTypes',
        'orderHoldReasons',
        'orderIssues',
        'orderLogisticsTypes',
        'orderNotificationTypes',
        'orderReturnCancelReasons',
        'orderReturnReasons',
        'orderReturnStatuses',
        'orderStatuses',
        'orderTypes',
        'productAttributeTypes',
        'productCategories',
        'projectAttachmentTypes',
        'projectContactTypes',
        'projectHoldReasonTypes',
        'projects',
        'projectScopeTypes',
        'projectStatuses',
        'projectTypes',
        'propertyAttachmentTypes',
        'propertyStatuses',
        'propertyTypes',
        'quoteIssues',
        'quotePdfTypes',
        'quoteReasons',
        'quoteReopenReasons',
        'quoteRequestRevisionTypes',
        'quoteRejectTypes',
        'quoteStatuses',
        'quoteTypes',
        'states',
        'templateTypes',
        'ticketProviders',
        'timezones',
        'tsmRegions',
        'unitOfMeasures',
        'usStates',
        'vendorManufacturers',
        'vendorsAll',
        'vendorsApproved',
        'warrantyOwnerTypes',
        'warrantyOwnerInstallTypes',
        'warrantyRegistrationStatuses',
        'workOrderContactPreferenceTypes',
        'workOrderContactTypes',
        'workOrderIssueRejectedTypes',
        'workOrderIssues',
        'workOrderPdfTypes',
        'workOrderPhotoTypes',
        'workOrderPriorityTypes',
        'workOrderRequirementApprovalReasons',
        'workOrderStatuses',
        'workOrderTypes',
        'assetRecommendationTypes',
        'assetRepairReasons',
        'lhsRiskTypes',
        'workOrderReassignReasons',
    ],
});

const api = apiWithTag.injectEndpoints({
    endpoints: build => ({
        accountDepartmentAssignmentTypes: build.query<
            AccountDepartmentAssignmentTypeAPI[],
            void
        >({
            queryFn: async () => {
                try {
                    const data = await accountDepartmentAssignmentTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['accountDepartmentAssignmentTypes'],
        }),
        accountDepartmentTypes: build.query<AccountDepartmentTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await accountDepartmentTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['accountDepartmentTypes'],
        }),
        accountPreferenceTypes: build.query<AccountPreferenceTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await accountPreferenceTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['accountPreferenceTypes'],
        }),
        accountRoles: build.query<AccountRoleAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await accountRoles();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['accountRoles'],
        }),
        accountRoleTypes: build.query<AccountRoleTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await accountRoleTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['accountRoleTypes'],
        }),
        assetConfigurationCategories: build.query<
            AssetConfigurationCategoryAPI[],
            void
        >({
            queryFn: async () => {
                try {
                    const data = await assetConfigurationCategories();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['assetConfigurationCategories'],
        }),
        assetPhotoTypes: build.query<AssetPhotoTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await assetPhotoTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['assetPhotoTypes'],
        }),
        assetRetirementReasons: build.query<AssetRetirementReasonAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await assetRetirementReasons();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['assetRetirementReasons'],
        }),
        assetStatuses: build.query<AssetStatusAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await assetStatuses();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['assetStatuses'],
        }),
        accountTypes: build.query<AccountTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await accountTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['accountTypes'],
        }),
        assetRecommendationTypes: build.query<
            AssetRecommendationTypeAPI[],
            void
        >({
            queryFn: async () => {
                try {
                    const data = await assetRecommendationTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['assetRecommendationTypes'],
        }),
        assetRepairReasons: build.query<AssetRepairReasonAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await assetRepairReasons();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['assetRepairReasons'],
        }),
        assetUntaggableReasons: build.query<AssetUntaggableReasonAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await assetUntaggableReasons();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['assetUntaggableReasons'],
        }),
        clients: build.query<ClientAPI[], void>({
            queryFn: async () => {
                try {
                    let data = [];
                    if (isClientAccount()) {
                        const account: {
                            clientTeam: {
                                clientId: number;
                            };
                        } = getAccount();
                        const client = await getCompany(
                            account.clientTeam.clientId
                        );
                        data = [client];
                    }
                    if (isMotiliAccount()) {
                        data = await clients();
                    }
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['clients'],
        }),
        clientBusinessChannels: build.query<ClientBusinessChannelAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await clientBusinessChannels();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['clientBusinessChannels'],
        }),
        clientFreightTypes: build.query<ClientFreightTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await clientFreightTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['clientFreightTypes'],
        }),
        clientIntegrationTypes: build.query<ClientIntegrationTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await clientIntegrationTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['clientIntegrationTypes'],
        }),
        clientOrderIssues: build.query<ClientOrderIssueAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await clientOrderIssues();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['clientOrderIssues'],
        }),
        clientSettings: build.query<ClientSettingAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await clientSettings();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['clientSettings'],
        }),
        clientStatusChangedNotificationConfig: build.query<
            ClientStatusChangedNotificationConfigAPI,
            void
        >({
            queryFn: async () => {
                try {
                    const data = await clientStatusChangedNotificationConfig();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['clientStatusChangedNotificationConfig'],
        }),
        clientStatuses: build.query<ClientStatus[], void>({
            queryFn: async () => {
                try {
                    const data = await clientStatuses();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['clientStatuses'],
        }),
        contractorBlocklistReasons: build.query<
            ContractorBlocklistReasonAPI[],
            void
        >({
            queryFn: async () => {
                try {
                    const data = await contractorBlocklistReasons();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['contractorBlocklistReasons'],
        }),
        contractorExternalCertifications: build.query<
            ContractorExternalCertificationAPI,
            void
        >({
            queryFn: async () => {
                try {
                    const data = await contractorExternalCertifications();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['contractorExternalCertifications'],
        }),
        contractorInternalCertifications: build.query<
            ContractorInternalCertificationAPI,
            void
        >({
            queryFn: async () => {
                try {
                    const data = await contractorInternalCertifications();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['contractorInternalCertifications'],
        }),
        contractorCredentials: build.query<ContractorCredentialAPI, void>({
            queryFn: async () => {
                try {
                    const data = await contractorCredentials();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['contractorCredentials'],
        }),
        contractorClients: build.query<ContractorClientAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await contractorClients();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['contractorClients'],
        }),
        contractorFileTypes: build.query<ContractorFileTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await contractorFileTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['contractorFileTypes'],
        }),
        contractorQualificationTypes: build.query<
            ContractorQualificationTypeAPI[],
            void
        >({
            queryFn: async () => {
                try {
                    const data = await contractorQualificationTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['contractorQualificationTypes'],
        }),
        contractorServiceTypes: build.query<
            ContractorServiceTypeAPI | {},
            void
        >({
            queryFn: async () => {
                try {
                    const data = await contractorServiceTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['contractorServiceTypes'],
        }),
        contractorStatuses: build.query<ContractorStatus[] | {}, void>({
            queryFn: async () => {
                try {
                    const data = await contractorStatuses();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['contractorStatuses'],
        }),
        contractorTeamMemberFileTypes: build.query<
            ContractorTeamMemberFileTypeAPI[],
            void
        >({
            queryFn: async () => {
                try {
                    const data = await contractorTeamMemberFileTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['contractorTeamMemberFileTypes'],
        }),
        contractorTeamMemberNotifications: build.query<
            ContractorTeamMemberNotificationAPI[],
            void
        >({
            queryFn: async () => {
                try {
                    const data = await contractorTeamMemberNotifications();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['contractorTeamMemberNotifications'],
        }),
        contractorTeamMemberTitles: build.query<
            ContractorTeamMemberTitleAPI[],
            void
        >({
            queryFn: async () => {
                try {
                    const data = await contractorTeamMemberTitles();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['contractorTeamMemberTitles'],
        }),
        contractorJobTypeEligibilityTypes: build.query<
            contractorJobTypeEligibilityTypeAPI[],
            void
        >({
            queryFn: async () => {
                try {
                    const data = await contractorJobTypeEligibilityTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['contractorJobTypeEligibilityTypes'],
        }),
        countries: build.query<CountryAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await countries();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['countries'],
        }),
        currencies: build.query<CurrencyAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await currencies();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['currencies'],
        }),
        directedInvoiceTypes: build.query<DirectedInvoiceTypesAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await directedInvoiceTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['directedInvoiceTypes'],
        }),
        documentTradeTypes: build.query<DocumentTradeTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await documentTradeTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['documentTradeTypes'],
        }),
        documentTypes: build.query<DocumentTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await documentTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['documentTypes'],
        }),
        draftStatuses: build.query<DraftStatusAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await draftStatuses();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['draftStatuses'],
        }),
        financialBillingStatuses: build.query<FinancialStatusAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await financialBillingStatuses();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['financialBillingStatuses'],
        }),
        financialPaymentStatuses: build.query<FinancialStatusAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await financialPaymentStatuses();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['financialPaymentStatuses'],
        }),
        financialInvoiceLineOfBusinesses: build.query<
            FinancialInvoiceLineOfBusinessesAPI,
            void
        >({
            queryFn: async () => {
                try {
                    const data = await financialInvoiceLineOfBusinesses();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['financialInvoiceLineOfBusinesses'],
        }),
        financialSalesOrderLineOfBusinesses: build.query<
            FinancialSalesOrderLineOfBusinessesAPI,
            void
        >({
            queryFn: async () => {
                try {
                    const data = await financialSalesOrderLineOfBusinesses();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['financialSalesOrderLineOfBusinesses'],
        }),
        financialStatuses: build.query<FinancialStatusAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await financialStatuses();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['financialStatuses'],
        }),
        financialSyncQueueModels: build.query<
            FinancialSyncQueueModelAPI[],
            void
        >({
            queryFn: async () => {
                try {
                    const data = await financialSyncQueueModels();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['financialSyncQueueModels'],
        }),
        financialSyncQueueResolveReasons: build.query<
            FinancialSyncQueueResolveReasonsAPI[],
            void
        >({
            queryFn: async () => {
                try {
                    const data = await financialSyncQueueResolveReasons();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['financialSyncQueueResolveReasons'],
        }),
        financialSyncQueueStatuses: build.query<
            FinancialSyncQueueStatusAPI[],
            void
        >({
            queryFn: async () => {
                try {
                    const data = await financialSyncQueueStatuses();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['financialSyncQueueStatuses'],
        }),
        financialTerms: build.query<FinancialTermAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await financialTerms();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['financialTerms'],
        }),
        folderAttachmentTypes: build.query<FolderAttachmentTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await folderAttachmentTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['folderAttachmentTypes'],
        }),
        adjustmentTypes: build.query<AdjustmentTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await adjustmentTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['adjustmentTypes'],
        }),
        lineItemAdjustmentReasonTypes: build.query<
            LineItemAdjustmentReasonTypeAPI[],
            void
        >({
            queryFn: async () => {
                try {
                    const data = await lineItemAdjustmentReasonTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['lineItemAdjustmentReasonTypes'],
        }),
        managedProductCategories: build.query<
            ManagedProductCategoryAPI[],
            void
        >({
            queryFn: async () => {
                try {
                    const data = await managedProductCategories();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['managedProductCategories'],
        }),
        managedProductAttributeTypes: build.query<
            ManagedProductAttributeTypeAPI[],
            void
        >({
            queryFn: async () => {
                try {
                    const data = await managedProductAttributeTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['managedProductAttributeTypes'],
        }),
        managedProductPricingTypes: build.query<
            ManagedProductPricingTypeAPI[],
            void
        >({
            queryFn: async () => {
                try {
                    const data = await managedProductPricingTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['managedProductPricingTypes'],
        }),
        managedVendorDistributionTypes: build.query<
            ManagedVendorDistributionTypeAPI[],
            void
        >({
            queryFn: async () => {
                try {
                    const data = await managedVendorDistributionTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['managedVendorDistributionTypes'],
        }),
        managedVendorIntegrationTypes: build.query<
            ManagedVendorIntegrationTypeAPI[],
            void
        >({
            queryFn: async () => {
                try {
                    const data = await managedVendorIntegrationTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['managedVendorIntegrationTypes'],
        }),
        managedVendorStatuses: build.query<ManagedVendorStatusAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await managedVendorStatuses();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['managedVendorStatuses'],
        }),
        managedVendorTypes: build.query<ManagedVendorTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await managedVendorTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['managedVendorTypes'],
        }),
        opsAccounts: build.query<OpsAccountAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await opsAccounts();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['opsAccounts'],
        }),
        orderCancelReasons: build.query<OrderContactTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await orderCancelReasons();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['orderCancelReasons'],
        }),
        orderContactTypes: build.query<OrderContactTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await orderContactTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['orderContactTypes'],
        }),
        orderFulfillmentMethods: build.query<orderFulfillmentMethodAPI[], void>(
            {
                queryFn: async () => {
                    try {
                        let data = [];
                        if (isMotiliAccount() || isClientAccount()) {
                            data = await orderFulfillmentMethods();
                        }
                        return { data };
                    } catch (error: any) {
                        return { error };
                    }
                },
                providesTags: ['orderFulfillmentMethods'],
            }
        ),
        orderFulfillmentTypes: build.query<OrderFulfillmentTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await orderFulfillmentTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['orderFulfillmentTypes'],
        }),
        orderHoldReasons: build.query<OrderFulfillmentTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await orderHoldReasons();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['orderHoldReasons'],
        }),
        orderIssues: build.query<OrderIssueAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await orderIssues();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['orderIssues'],
        }),
        orderLogisticsTypes: build.query<OrderLogisticsTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await orderLogisticsTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['orderLogisticsTypes'],
        }),
        orderNotificationTypes: build.query<OrderNotificationTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await orderNotificationTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['orderNotificationTypes'],
        }),
        orderReturnCancelReasons: build.query<
            OrderReturnCancelReasonApi[],
            void
        >({
            queryFn: async () => {
                try {
                    const data = await orderReturnCancelReasons();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['orderReturnCancelReasons'],
        }),
        orderReturnReasons: build.query<OrderReturnReasonApi[], void>({
            queryFn: async () => {
                try {
                    const data = await orderReturnReasons();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['orderReturnReasons'],
        }),
        orderReturnStatuses: build.query<OrderReturnStatusApi[], void>({
            queryFn: async () => {
                try {
                    const data = await orderReturnStatuses();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['orderReturnStatuses'],
        }),
        orderStatuses: build.query<OrderStatusAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await orderStatuses();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['orderStatuses'],
        }),
        orderTypes: build.query<OrderTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await orderTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['orderTypes'],
        }),
        productAttributeTypes: build.query<ProductAttributeTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await productAttributeTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['productAttributeTypes'],
        }),
        productCategories: build.query<ProductCategoryAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await productCategories();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['productCategories'],
        }),
        projectAttachmentTypes: build.query<ProjectAttachmentTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await projectAttachmentTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['projectAttachmentTypes'],
        }),
        projectContactTypes: build.query<ProjectContactTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await projectContactTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['projectContactTypes'],
        }),
        projectHoldReasonTypes: build.query<ProjectHoldReasonTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await projectHoldReasonTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['projectHoldReasonTypes'],
        }),
        projects: build.query<ProjectAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await projects();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['projectScopeTypes'],
        }),
        projectScopeTypes: build.query<ProjectScopeTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await projectScopeTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['projectScopeTypes'],
        }),
        projectStatuses: build.query<ProjectStatusAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await projectStatuses();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['projectStatuses'],
        }),
        projectTypes: build.query<ProjectTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await projectTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['projectTypes'],
        }),
        propertyAttachmentTypes: build.query<PropertyAttachmentTypeAPI[], void>(
            {
                queryFn: async () => {
                    try {
                        const data = await propertyAttachmentTypes();
                        return { data };
                    } catch (error: any) {
                        return { error };
                    }
                },
                providesTags: ['propertyAttachmentTypes'],
            }
        ),
        propertyStatuses: build.query<PropertyStatusAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await propertyStatuses();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['propertyStatuses'],
        }),
        propertyTypes: build.query<PropertyTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await propertyTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['propertyTypes'],
        }),
        quotePdfTypes: build.query<QuotePDFTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await quotePdfTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['quotePdfTypes'],
        }),
        quoteReopenReasons: build.query<QuoteReopenReasonsAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await quoteReopenReasons();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['quoteReopenReasons'],
        }),
        quoteRequestRevisionTypes: build.query<
            QuoteRequestRevisionTypeAPI[],
            void
        >({
            queryFn: async () => {
                try {
                    const data = await quoteRequestRevisionTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['quoteRequestRevisionTypes'],
        }),
        quoteRejectTypes: build.query<QuoteRejectTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await quoteRejectTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['quoteRejectTypes'],
        }),
        quoteIssues: build.query<QuoteIssueAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await quoteIssues();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['quoteIssues'],
        }),
        quoteReasons: build.query<QuoteReasonsAPI, void>({
            queryFn: async () => {
                try {
                    const data = await quoteReasons();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['quoteReasons'],
        }),
        quoteStatuses: build.query<QuoteStatusAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await quoteStatuses();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['quoteStatuses'],
        }),
        quoteTypes: build.query<QuoteTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await quoteTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['quoteTypes'],
        }),
        states: build.query<StateAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await states();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['states'],
        }),
        templateTypes: build.query<TemplateTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await templateTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['templateTypes'],
        }),
        ticketProviders: build.query<TicketProviderAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await ticketProviders();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['ticketProviders'],
        }),
        timezones: build.query<string[], void>({
            queryFn: async () => {
                try {
                    const data = await timezones();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['timezones'],
        }),
        tsmRegions: build.query<TSM_Regions_API[], void>({
            queryFn: async () => {
                try {
                    const data = await tsmRegions();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['tsmRegions'],
        }),
        unitOfMeasures: build.query<UnitsofMeasureAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await unitOfMeasures();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['unitOfMeasures'],
        }),
        usStates: build.query<StateAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await usStates();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['usStates'],
        }),
        vendorsAll: build.query<VendorAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await vendorsAll();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['vendorsAll'],
        }),
        vendorsApproved: build.query<VendorAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await vendorsApproved();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['vendorsApproved'],
        }),
        vendorManufacturers: build.query<VendorAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await vendorManufacturers();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['vendorManufacturers'],
        }),
        warrantyOwnerTypes: build.query<WarrantyOwnerTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await warrantyOwnerTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['warrantyOwnerTypes'],
        }),
        warrantyOwnerInstallTypes: build.query<
            WarrantyOwnerInstallTypeAPI[],
            void
        >({
            queryFn: async () => {
                try {
                    const data = await warrantyOwnerInstallTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['warrantyOwnerInstallTypes'],
        }),
        warrantyRegistrationStatuses: build.query<
            WarrantyRegistrationStatusesAPI[],
            void
        >({
            queryFn: async () => {
                try {
                    const data = await warrantyRegistrationStatuses();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['warrantyRegistrationStatuses'],
        }),
        workOrderContactPreferenceTypes: build.query<
            WorkOrderContactPreferenceTypeAPI[],
            void
        >({
            queryFn: async () => {
                try {
                    const data = await workOrderContactPreferenceTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['workOrderContactPreferenceTypes'],
        }),
        workOrderContactTypes: build.query<WorkOrderContactTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await workOrderContactTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['workOrderContactTypes'],
        }),
        workOrderIssueRejectedTypes: build.query<
            WorkOrderIssueRejectedTypeAPI[],
            void
        >({
            queryFn: async () => {
                try {
                    const data = await workOrderIssueRejectedTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['workOrderIssueRejectedTypes'],
        }),
        workOrderIssues: build.query<WorkOrderIssueAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await workOrderIssues();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['workOrderIssues'],
        }),
        workOrderPdfTypes: build.query<WorkOrderPDFTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await workOrderPdfTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['workOrderPdfTypes'],
        }),
        workOrderPhotoTypes: build.query<WorkOrderPhotoTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await workOrderPhotoTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['workOrderPhotoTypes'],
        }),
        workOrderPriorityTypes: build.query<WorkOrderPriorityTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await workOrderPriorityTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['workOrderPriorityTypes'],
        }),
        workOrderRequirementApprovalReasons: build.query<
            WorkOrderRequirementApprovalReasonAPI[],
            void
        >({
            queryFn: async () => {
                try {
                    const data = await workOrderRequirementApprovalReasons();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['workOrderRequirementApprovalReasons'],
        }),
        workOrderStatuses: build.query<WorkOrderStatusAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await workOrderStatuses();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['workOrderStatuses'],
        }),
        workOrderTypes: build.query<WorkOrderTypeAPI[], void>({
            queryFn: async () => {
                try {
                    const data = await workOrderTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['workOrderTypes'],
        }),
        lhsRiskTypes: build.query<LHSRiskType[], void>({
            queryFn: async () => {
                try {
                    const data = await lhsRiskTypes();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['lhsRiskTypes'],
        }),
        workOrderReassignReasons: build.query<
            WorkOrderReassignmentReasonAPI[],
            void
        >({
            queryFn: async () => {
                try {
                    const data = await workOrderReassignReasons();
                    return { data };
                } catch (error: any) {
                    return { error };
                }
            },
            providesTags: ['workOrderReassignReasons'],
        }),
    }),
});

export const {
    useAccountRolesQuery,
    useAccountDepartmentAssignmentTypesQuery,
    useAccountDepartmentTypesQuery,
    useAccountPreferenceTypesQuery,
    useAccountRoleTypesQuery,
    useAccountTypesQuery,
    useAssetConfigurationCategoriesQuery,
    useAssetPhotoTypesQuery,
    useAssetRecommendationTypesQuery,
    useAssetRepairReasonsQuery,
    useAssetRetirementReasonsQuery,
    useAssetStatusesQuery,
    useAssetUntaggableReasonsQuery,
    useClientBusinessChannelsQuery,
    useClientsQuery,
    useClientFreightTypesQuery,
    useClientIntegrationTypesQuery,
    useClientOrderIssuesQuery,
    useClientStatusChangedNotificationConfigQuery,
    useClientSettingsQuery,
    useClientStatusesQuery,
    useContractorBlocklistReasonsQuery,
    useContractorCredentialsQuery,
    useContractorClientsQuery,
    useContractorExternalCertificationsQuery,
    useContractorInternalCertificationsQuery,
    useContractorFileTypesQuery,
    useContractorQualificationTypesQuery,
    useContractorServiceTypesQuery,
    useContractorStatusesQuery,
    useContractorTeamMemberFileTypesQuery,
    useContractorTeamMemberNotificationsQuery,
    useContractorTeamMemberTitlesQuery,
    useContractorJobTypeEligibilityTypesQuery,
    useCountriesQuery,
    useDirectedInvoiceTypesQuery,
    useDocumentTradeTypesQuery,
    useDocumentTypesQuery,
    useDraftStatusesQuery,
    useFinancialInvoiceLineOfBusinessesQuery,
    useFinancialSalesOrderLineOfBusinessesQuery,
    useFinancialStatusesQuery,
    useFinancialSyncQueueResolveReasonsQuery,
    useFinancialSyncQueueModelsQuery,
    useFinancialSyncQueueStatusesQuery,
    useFinancialTermsQuery,
    useFinancialBillingStatusesQuery,
    useFinancialPaymentStatusesQuery,
    useFolderAttachmentTypesQuery,
    useAdjustmentTypesQuery,
    useLineItemAdjustmentReasonTypesQuery,
    useManagedProductAttributeTypesQuery,
    useManagedProductPricingTypesQuery,
    useManagedVendorDistributionTypesQuery,
    useManagedVendorIntegrationTypesQuery,
    useManagedProductCategoriesQuery,
    useManagedVendorStatusesQuery,
    useManagedVendorTypesQuery,
    useOpsAccountsQuery,
    useOrderCancelReasonsQuery,
    useOrderContactTypesQuery,
    useOrderFulfillmentMethodsQuery,
    useOrderFulfillmentTypesQuery,
    useOrderHoldReasonsQuery,
    useOrderIssuesQuery,
    useOrderLogisticsTypesQuery,
    useOrderNotificationTypesQuery,
    useOrderReturnCancelReasonsQuery,
    useOrderReturnReasonsQuery,
    useOrderReturnStatusesQuery,
    useOrderStatusesQuery,
    useOrderTypesQuery,
    useProductAttributeTypesQuery,
    useProductCategoriesQuery,
    useProjectAttachmentTypesQuery,
    useProjectContactTypesQuery,
    useProjectHoldReasonTypesQuery,
    useProjectScopeTypesQuery,
    useProjectStatusesQuery,
    useProjectTypesQuery,
    usePropertyAttachmentTypesQuery,
    usePropertyStatusesQuery,
    usePropertyTypesQuery,
    useQuoteIssuesQuery,
    useQuotePdfTypesQuery,
    useQuoteReasonsQuery,
    useQuoteReopenReasonsQuery,
    useQuoteRequestRevisionTypesQuery,
    useQuoteRejectTypesQuery,
    useQuoteTypesQuery,
    useQuoteStatusesQuery,
    useStatesQuery,
    useTemplateTypesQuery,
    useTicketProvidersQuery,
    useTimezonesQuery,
    useTsmRegionsQuery,
    useVendorManufacturersQuery,
    useVendorsAllQuery,
    useVendorsApprovedQuery,
    useUsStatesQuery,
    useWarrantyOwnerTypesQuery,
    useWarrantyOwnerInstallTypesQuery,
    useWarrantyRegistrationStatusesQuery,
    useWorkOrderContactPreferenceTypesQuery,
    useWorkOrderContactTypesQuery,
    useWorkOrderIssuesQuery,
    useWorkOrderIssueRejectedTypesQuery,
    useWorkOrderPdfTypesQuery,
    useWorkOrderPhotoTypesQuery,
    useWorkOrderPriorityTypesQuery,
    useWorkOrderRequirementApprovalReasonsQuery,
    useWorkOrderStatusesQuery,
    useWorkOrderTypesQuery,
    useLhsRiskTypesQuery,
    useWorkOrderReassignReasonsQuery,
} = api;
export default api;
