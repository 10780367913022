/**
 * Copyright Motili Inc., 2023 All Rights Reserved
 */

/* eslint-disable class-methods-use-this */

import { useContext, useState, useEffect, lazy } from 'react';
import { useLocation, Navigate } from 'react-router-dom';

import { ServiceWorkerContext } from 'useServiceWorker';
import { UserTokenContext } from 'useUserToken';
import AuthStore from './stores/AuthStore';
import { isMotiliAccount, logout } from './services/AuthService';
import UpdateAppModal from './components/modal/UpdateAppModal';
import TimeoutModal from './components/modal/TimeoutModal';
import { MessagingRegister } from './components/messaging/MessagingRegister';

const AdminRouter = lazy(() => import('../admin/AdminRouter'));
const ClientRouter = lazy(() => import('../client/ClientRouter'));
const ContractorRouter = lazy(() => import('../contractor/ContractorRouter'));

export default function AuthenticatedRouter(props) {
    const serviceWorker = useContext(ServiceWorkerContext);
    const userToken = useContext(UserTokenContext);

    const { isUpdateAvailable, updateAssets } = serviceWorker;
    const { showTimeoutDialog, expiredIn, extendToken } = userToken;
    let dialog;
    if (isUpdateAvailable) {
        dialog = <UpdateAppModal handleUpdate={updateAssets} />;
    } else if (showTimeoutDialog) {
        dialog = (
            <TimeoutModal
                handleExtend={extendToken}
                handleClose={() => {}}
                expiredIn={expiredIn}
            />
        );
    }
    return (
        <>
            {dialog}
            <AuthenticatedRouterComponent {...props} />
        </>
    );
}

const AuthenticatedRouterComponent = () => {
    const location = useLocation();
    const [authState, setAuthState] = useState(AuthStore.getState());

    useEffect(() => {
        const unlisten = AuthStore.listen(onChange);
        setTimeout(() => handleZenDeskChat(), 1500);
        return () => unlisten();
    }, []);

    const onChange = state => {
        setAuthState(prevAuthState => ({ ...prevAuthState, ...state }));
    };

    const handleZenDeskChat = () => {
        if (window && window.$zopim.livechat && window.$zopim.livechat.window) {
            const script = document.createElement('script');
            script.async = true;
            if (isMotiliAccount()) {
                script.innerHTML = '$zopim.livechat.hideAll()';
            } else {
                script.innerHTML = '$zopim.livechat.button.show()';
            }
            document.body.appendChild(script);
        }
    };

    if (authState.authenticated) {
        if (authState.isMotiliAccount) {
            return (
                <>
                    <MessagingRegister />
                    <AdminRouter redirect={location?.search} />
                </>
            );
        }

        if (authState.isClientAccount) {
            return (
                <>
                    <MessagingRegister />
                    <ClientRouter redirect={location?.search} />
                </>
            );
        }

        if (authState.isContractorAccount) {
            return (
                <>
                    <MessagingRegister />
                    <ContractorRouter redirect={location?.search} />
                </>
            );
        }
        // Log out user due to invalid auth
        console.log('invalid auth, logging out');
        logout();
    }
    return (
        <Navigate
            to={{
                pathname: '/login',
                search: `redirectTo=${location?.pathname}`,
            }}
        />
    );
};
