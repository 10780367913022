/**
 * @license
 * @copyright Copyright Motili Inc., 2023 All Rights Reserved
 */

import {
    createContext,
    useState,
    useMemo,
    useCallback,
    useEffect,
    useContext,
} from 'react';
import Config from 'Config';
import {
    isLoggedIn,
    refreshToken,
    getExpireIn,
    logout,
    extendToken,
    getToken,
} from 'common/services/AuthService.js';
import useInterval from 'use-interval';
import AuthActions from 'common/actions/AuthActions';
import { BroadcastChannel } from 'broadcast-channel';

export const UserTokenContext = createContext({});

export const UserTokenProvider = ({ children }) => {
    const defaultIntervalCheck = 60000;
    const intervalCheckWhenDialogShow = 1000;
    const [showTimeoutDialog, setShowTimeoutDialog] = useState(false);
    const [expiredIn, setExpiredIn] = useState(0);
    const [intervalCheckToken, setIntervalCheckToken] =
        useState(defaultIntervalCheck);
    const [isMounted, setIsMounted] = useState(true);

    const motiliAppChannel = useMemo(() => {
        return new BroadcastChannel('motili-app-channel');
    }, []);

    const broadcastMotiliAppMessage = useCallback(
        async eventMessage => {
            if (motiliAppChannel) {
                await motiliAppChannel.postMessage(eventMessage);
            }
        },
        [motiliAppChannel]
    );
    const calculateSessionTimeout = useCallback(async () => {
        try {
            await refreshToken();
            const _expiredIn = getExpireIn();
            if (_expiredIn > 0) {
                const _showTimeoutDialog =
                    Config.tokenValidation.timeBeforeExpired * 60 >= _expiredIn;
                if (_showTimeoutDialog) {
                    // change interval to 1 seconds for update UI
                    if (isMounted) {
                        setIntervalCheckToken(intervalCheckWhenDialogShow);
                    }
                } else if (isMounted) {
                    // reset interval to default
                    setIntervalCheckToken(defaultIntervalCheck);
                }
                if (isMounted) {
                    setShowTimeoutDialog(_showTimeoutDialog);
                    setExpiredIn(_expiredIn);
                }
            } else {
                console.log('session timeout, logging out');
                logout();
                await broadcastMotiliAppMessage({
                    type: 'USER_SESSION',
                    loggedIn: false,
                });
            }
        } catch (err) {
            console.error('validate token error', err);
        }
    }, [broadcastMotiliAppMessage, isMounted]);

    const extendUserToken = useCallback(async () => {
        setIntervalCheckToken(defaultIntervalCheck);
        setShowTimeoutDialog(false);
        await extendToken();
        await broadcastMotiliAppMessage({
            type: 'USER_SESSION_TIMEOUT',
            showTimeoutDialog: false,
        });
    }, [broadcastMotiliAppMessage]);

    /**
     * Extend token when web reload or first hit url
     */
    useEffect(() => {
        (async () => {
            await extendUserToken();
        })();
        return () => {
            setIsMounted(false);
        };
    }, [extendUserToken]);

    /**
     * Broadcast message for timeout dialog status
     */
    useEffect(() => {
        (async () => {
            if (showTimeoutDialog) {
                await broadcastMotiliAppMessage({
                    type: 'USER_SESSION_TIMEOUT',
                    showTimeoutDialog,
                });
            }
        })();
    }, [showTimeoutDialog, broadcastMotiliAppMessage]);

    /**
     * Handle broadcast massage
     */
    useEffect(() => {
        (async () => {
            // Handle on message broadcast
            if (motiliAppChannel) {
                motiliAppChannel.onmessage = async message => {
                    try {
                        if (message?.type === 'USER_SESSION') {
                            if (message?.loggedIn) {
                                await calculateSessionTimeout();
                                const token = getToken();
                                if (token) {
                                    AuthActions.fetchedToken(token);
                                } else {
                                    console.log('no session token, logging out');
                                    logout();
                                }
                            } else {
                                console.log('not logged in, logging out');
                                logout();
                            }
                        } else if (message?.type === 'USER_SESSION_TIMEOUT') {
                            if (isMounted) {
                                setShowTimeoutDialog(
                                    message?.showTimeoutDialog
                                );

                                if (message?.showTimeoutDialog) {
                                    setIntervalCheckToken(
                                        intervalCheckWhenDialogShow
                                    );
                                } else {
                                    setIntervalCheckToken(defaultIntervalCheck);
                                }
                            }
                        }
                    } catch (error) {
                        console.error(
                            'Error during processing broadcast message',
                            {
                                message,
                                error,
                            }
                        );
                    }
                };
            }
        })();
    }, [motiliAppChannel, calculateSessionTimeout, isMounted]);

    /**
     * Interval for check user session
     */
    useInterval(
        async () => {
            if (isLoggedIn()) {
                await calculateSessionTimeout();
            } else {
                if (isMounted) {
                    setIntervalCheckToken(null);
                }
                await broadcastMotiliAppMessage({
                    type: 'USER_SESSION',
                    loggedIn: false,
                });
            }
        },
        intervalCheckToken,
        true
    );

    const value = useMemo(
        () => ({
            showTimeoutDialog,
            expiredIn,
            extendToken: extendUserToken,
            setLoggedIn: async loggedIn => {
                if (loggedIn) {
                    await calculateSessionTimeout();
                }
                await broadcastMotiliAppMessage({
                    type: 'USER_SESSION',
                    loggedIn,
                });
            },
        }),
        [
            showTimeoutDialog,
            expiredIn,
            broadcastMotiliAppMessage,
            calculateSessionTimeout,
            extendUserToken,
        ]
    );

    return (
        <UserTokenContext.Provider value={value}>
            {children}
        </UserTokenContext.Provider>
    );
};

export const useUserToken = () => {
    return useContext(UserTokenContext);
};
