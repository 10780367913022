/**
 * @license
 * @copyright Copyright Motili Inc., 2022 All Rights Reserved
 */
import moment from 'moment-timezone';
import _ from 'lodash';

/**
 * @type {{[key: string]: string}}
 */
export const timezoneAbbreviationsToIana = {
    'US/EASTERN': 'America/New_York',
    'US/HAWAII': 'Pacific/Honolulu',
    'US/ALASKA': 'America/Anchorage',
    'US/EAST-INDIANA': 'America/New_York',
    'US/INDIANA-STARKE': 'America/Indiana/Knox',
    'US/PACIFIC': 'America/Los_Angeles',
    'US/MICHIGAN': 'America/Detroit',
    'US/CENTRAL': 'America/Chicago',
    'America/Toronto': 'America/Toronto',
    'America/Halifax': 'America/Halifax',
    Central: 'America/Chicago',
    'America/St_Johns': 'America/St_Johns',
    'America/Vancouver': 'America/Vancouver',
    'US/ARIZONA': 'America/Phoenix',
    'US/MOUNTAIN': 'America/Denver',
    'America/Anchorage': 'America/Anchorage',
    'America/Edmonton': 'America/Edmonton',
    'US/INDIANA-EASTERN': 'America/New_York',
};

const monthsShort = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];
moment.updateLocale('en', {
    monthsShort: {
        format: monthsShort,
        standalone: monthsShort,
    },
});

/**
 *
 * @type {{month: string, hour: string, ampm: string, dateSeparator: string, year: string, timezone: string, dateTimeSeparator: string, timeSeparator: string, timeAndTimezoneSeparator: string, day: string, timeAndAMPMSeparator: string, minute: string, dayOfWeek: string, dayOfWeekSeparator: string}}
 */
const defaultFormat = {
    day: 'DD',
    dayOfWeek: 'ddd',
    month: 'MMM',
    year: 'YYYY',
    hour: 'h',
    minute: 'mm',
    ampm: 'A',
    timezone: 'z',
    dateSeparator: ' ',
    timeSeparator: ':',
    timeAndAMPMSeparator: ' ',
    timeAndTimezoneSeparator: ' ',
    dateTimeSeparator: ' ',
    dayOfWeekSeparator: ', ',
};

/**
 *
 * @return {{month: string, hour: string, ampm: string, dateSeparator: string, year: string, timezone: string, dateTimeSeparator: string, timeSeparator: string, timeAndTimezoneSeparator: string, day: string, timeAndAMPMSeparator: string, minute: string, dayOfWeek: string, dayOfWeekSeparator: string}}
 */
const getFormatConfig = () => {
    return defaultFormat;
};

/**
 * Used to help retrieve the day before the current day.
 * Passes in available days for date ranges (moment objects) and returns false if day is before then.
 *
 * @param {moment.Moment} day
 *
 * @return {boolean}
 */
export const isDayBeforeCurrentDay = day => {
    return moment().endOf('day').diff(day, 'days') > 0;
};

/**
 * Used to help validate if a day is after today's date.
 *
 * @param {moment.Moment} day
 *
 * @return {boolean}
 */
export const isDayAfterCurrentDay = day => {
    return moment().startOf('day').diff(day, 'days') < 0;
};

/**
 * boolean check for validation with a certain time using moment
 *
 * @param {moment} time
 * @param {moment} minDateTime
 *
 * @return {boolean}
 */
export const isTimeBeforeValidTime = (time, minDateTime) => {
    return moment(time).isBefore(minDateTime);
};

/**
 * convert data-time string to DateTime Object
 *
 * @param {string|moment|null|undefined|moment.Moment}dateTime
 * @param {string}[toTimezone],
 * @param {boolean}[keepLocalTime],
 *
 * @return {*|moment}
 */
export const toDateTimeObject = (dateTime, toTimezone, keepLocalTime) => {
    let dateTimeObject;
    if (dateTime instanceof moment) {
        dateTimeObject = dateTime;
    } else if (dateTime) {
        dateTimeObject = moment(dateTime);
    }
    if (!isValidDateTime(dateTimeObject)) {
        return;
    }
    if (toTimezone) {
        if (keepLocalTime) {
            dateTimeObject.tz(toTimezone, keepLocalTime);
        } else {
            dateTimeObject.tz(toTimezone);
        }
    }
    return dateTimeObject;
};

/**
 * valid datetime object
 * @param {*|moment}dateTime
 * @return {boolean}
 */
export const isValidDateTime = dateTime => {
    if (!dateTime) {
        return false;
    }
    if (!(dateTime instanceof moment)) {
        return false;
    }
    if (!dateTime.isValid()) {
        return false;
    }
    return true;
};

/**
 * Get Date Format
 *
 * @param {object} [options]
 * @param {string} [options.dateSeparator]
 * @param {boolean} [options.hideYear]
 * @param {boolean} [options.showDayOfWeek]
 * @param {string} [options.dayOfWeekSeparator]
 * @param {string} [options.monthFormat]
 *
 * @return {string}
 */
export const getDateFormat = options => {
    const formatConfig = getFormatConfig();
    let dateSeparator = formatConfig.dateSeparator;
    if (options?.dateSeparator !== undefined) {
        dateSeparator = options.dateSeparator;
    }
    let yearFormat = `${dateSeparator}${formatConfig.year}`;
    if (options?.hideYear) {
        yearFormat = '';
    }
    let dayOfWeekFormat = '';
    if (options?.showDayOfWeek) {
        dayOfWeekFormat = `${formatConfig.dayOfWeek}${dateSeparator}`;
        let dayOfWeekSeparator = formatConfig.dayOfWeekSeparator;
        if (options?.dayOfWeekSeparator) {
            dayOfWeekSeparator = options.dayOfWeekSeparator;
        }
        dayOfWeekFormat = `${formatConfig.dayOfWeek}${dayOfWeekSeparator}`;
    }
    let monthFormat = formatConfig.month;
    if (options?.monthFormat) {
        monthFormat = options.monthFormat;
    }

    return `${dayOfWeekFormat}${monthFormat}${dateSeparator}${formatConfig.day}${yearFormat}`;
};

/**
 * Get a Moment Object from a 24 hour time based meridan based time string
 * @param {string} [timeString]
 * @param {Moment} [date]
 * @return {Moment}
 */
export const createMomentTimeObject = (timeString, date) => {
    const timeFormat = /^(1[0-2]|0?[1-9]):[0-5][0-9] (AM|PM)$/i;
    if (!timeString || !timeFormat.test(timeString)) {
        return null;
    }
    try {
        let [hour, minute] = timeString.split(':');
        const meridiem = minute.slice(-2);
        minute = minute.slice(0, -2).trim();

        if (meridiem.toLowerCase() === 'pm' && hour !== '12') {
            const calculateHour = parseInt(hour, 10) + 12;
            hour = calculateHour.toString();
        } else if (meridiem.toLowerCase() === 'am' && hour === '12') {
            hour = '00';
        }
        date.hour(hour);
        date.minute(minute);
        return date;
    } catch (error) {
        return null;
    }
};

/**
 * Get Time Format
 * @param {object} [options]
 * @param {string} [options.timeSeparator]
 * @param {string} [options.timeAndAMPMSeparator]
 * @return {string}
 */
const getTimeFormat = options => {
    const formatConfig = getFormatConfig();
    let timeAndAMPMSeparator = formatConfig.timeAndAMPMSeparator;
    if (options?.timeAndAMPMSeparator !== undefined) {
        timeAndAMPMSeparator = options.timeAndAMPMSeparator;
    }
    let timezoneFmt = '';
    if (options?.showTimezone) {
        timezoneFmt = `${formatConfig.timeAndTimezoneSeparator}${formatConfig.timezone}`;
    }

    return `${formatConfig.hour}${formatConfig.timeSeparator}${formatConfig.minute}${timeAndAMPMSeparator}${formatConfig.ampm}${timezoneFmt}`;
};

/**
 * Get Date Time Format
 * @param {object} [options]
 * @param {string} [options.dateSeparator]
 * @param {string} [options.timeSeparator]
 * @param {string} [options.dateTimeSeparator]
 * @param {string} [options.timeAndAMPMSeparator]
 * @param {boolean} [options.hideYear]
 * @param {boolean} [options.showDayOfWeek]
 * @param {string} [options.dayOfWeekSeparator]
 *
 * @return {string}
 */
export const getDateTimeFormat = options => {
    const formatConfig = getFormatConfig();
    const dateFormat = getDateFormat(options);
    const timeFormat = getTimeFormat(options);
    let dateTimeSeparator = formatConfig.dateTimeSeparator;
    if (options?.dateTimeSeparator !== undefined) {
        dateTimeSeparator = options.dateTimeSeparator;
    }

    return `${dateFormat}${dateTimeSeparator}${timeFormat}`;
};

/**
 * Get Date Time and Timezone Format
 * @param {object} [options]
 * @param {string} [options.dateSeparator]
 * @param {string} [options.timeSeparator]
 * @param {string} [options.dateTimeSeparator]
 * @param {string} [options.timeAndAMPMSeparator]
 * @param {string} [options.timeAndTimezoneSeparator]
 * @param {boolean} [options.hideYear]
 * @param {boolean} [options.showDayOfWeek]
 * @param {string} [options.dayOfWeekSeparator]
 *
 * @return {string}
 */
const getDateTimeWithTimezoneFormat = options => {
    const formatConfig = getFormatConfig();
    const dateFormat = getDateFormat(options);
    const timeFormat = getTimeFormat(options);

    let dateTimeSeparator = formatConfig.dateTimeSeparator;
    if (options?.dateTimeSeparator !== undefined) {
        dateTimeSeparator = options.dateTimeSeparator;
    }
    let timeAndTimezoneSeparator = formatConfig.timeAndTimezoneSeparator;
    if (options?.timeAndTimezoneSeparator !== undefined) {
        timeAndTimezoneSeparator = options.timeAndTimezoneSeparator;
    }

    return `${dateFormat}${dateTimeSeparator}${timeFormat}${timeAndTimezoneSeparator}${formatConfig.timezone}`;
};

/**
 * Get Format data
 *
 * @return {string|undefined}
 */
const formatData = (dateTime, format, options) => {
    const dateTimeObject = toDateTimeObject(dateTime);
    if (!dateTimeObject) {
        return returnInvalidData(options);
    }

    return dateTimeObject.format(format);
};

/**
 *
 * @param {object}[options]
 * @param {string}[options.invalidDisplay]
 * @return {*|undefined|string}
 */
const returnInvalidData = options => {
    if (options?.invalidDisplay !== undefined) {
        return options.invalidDisplay;
    }
    return '';
};

/**
 *
 * @param {moment.Moment|string|Date|null|undefined}dateTime
 * @param {object} [options]
 * @param {string} [options.dateSeparator]
 * @param {string} [options.invalidDisplay]
 * @param {boolean} [options.hideYear]
 * @param {boolean} [options.showDayOfWeek]
 * @param {string} [options.dayOfWeekSeparator]
 */
export const displayDate = (dateTime, options) => {
    return formatData(dateTime, getDateFormat(options), options);
};

/**
 *
 * @param {moment.Moment|string}dateTime
 * @param {object} [options]
 * @param {string} [options.timeSeparator]
 * @param {string} [options.timeAndAMPMSeparator]
 * @param {string} [options.invalidDisplay]
 * @param {boolean} [options.showTimezone]
 */
export const displayTime = (dateTime, options) => {
    return formatData(dateTime, getTimeFormat(options), options);
};

/**
 *
 * @param {moment.Moment|string}dateTime
 * @param {object} [options]
 * @param {string} [options.timeSeparator]
 * @param {string} [options.timeAndAMPMSeparator]
 * @param {string} [options.invalidDisplay]
 */
export const displayTimeWithTimezone = (dateTime, options) => {
    return displayTime(dateTime, { ...(options || {}), showTimezone: true });
};

/**
 *
 * @param {moment.Moment|string}dateTime
 * @param {object} [options]
 * @param {string} [options.dateSeparator]
 * @param {string} [options.timeSeparator]
 * @param {string} [options.dateTimeSeparator]
 * @param {string} [options.timeAndAMPMSeparator]
 * @param {string} [options.invalidDisplay]
 * @param {boolean} [options.hideYear]
 */
export const displayDateTime = (dateTime, options) => {
    return formatData(dateTime, getDateTimeFormat(options), options);
};

/**
 *
 * @param {moment.Moment|string}dateTime
 * @param {object} [options]
 * @param {string} [options.dateSeparator]
 * @param {string} [options.timeSeparator]
 * @param {string} [options.dateTimeSeparator]
 * @param {string} [options.timeAndAMPMSeparator]
 * @param {string} [options.timeAndTimezoneSeparator]
 * @param {string} [options.invalidDisplay]
 * @param {boolean} [options.hideYear]
 */
export const displayDateTimeWithTimezone = (dateTime, options) => {
    return formatData(
        dateTime,
        getDateTimeWithTimezoneFormat(options),
        options
    );
};

export const displayDateTimeRange = (startDateTime, endDateTime) => {
    const formatConfig = getFormatConfig();
    const startDateTimeObject = toDateTimeObject(startDateTime);
    const timeOptions = { timeAndAMPMSeparator: '' };
    const startDate = displayDate(startDateTime);
    const endDate = displayDate(endDateTime);
    const startTime = displayTime(startDateTime, timeOptions);
    const endTime = displayTime(endDateTime, timeOptions);
    const timezone = startDateTimeObject.format(formatConfig.timezone);

    if (startDate === endDate) {
        return `${startDate}, ${startTime} - ${endTime} ${timezone}`;
    }
    return `${startDate}, ${startTime} - ${endDate}, ${endTime} ${timezone}`;
};

export const processStartDate = date => {
    if (date === null) {
        return null;
    }
    if (!date) {
        return undefined;
    }
    if (moment.isMoment(date) && date.isValid()) {
        return _.cloneDeep(date).utc().startOf('day');
    }
    return _.cloneDeep(date);
};

export const processEndDate = date => {
    if (date === null) {
        return null;
    }
    if (!date) {
        return undefined;
    }
    if (moment.isMoment(date) && date.isValid()) {
        return _.cloneDeep(date).utc().endOf('day');
    }
    return _.cloneDeep(date);
};

export const processNow = date => {
    return date && toDateTimeObject(date)
        ? moment()
              .set('year', date.get('year'))
              .set('month', date.get('month'))
              .set('date', date.get('date'))
        : _.cloneDeep(date);
};
